import React from 'react';
import cls from 'classnames';

import useLoop2 from 'utils/useLoop2';

import styles from './style.module.less';

export default ({ title, description, bgColor = 'light', list }) => {
  const [curr, icurr, { goto }] = useLoop2([0, 0], {
    totalSize: [list.length, list.map((x) => x.previews.length)],
  });

  return (
    <div className={cls([styles.container, bgColor])}>
      <div className={styles.section}>
        <div className={styles.navs}>
          {(list || []).map(({ step, title, desc }, i) => {
            return (
              <div
                key={step}
                className={cls([styles.nav, i === curr && 'active'])}
                onMouseEnter={() => {
                  goto(i, 0);
                }}
              >
                <div className={styles.navContent}>
                  <div className={styles.navTitle}>
                    <div className={styles.navTitleStep}>{step}</div>
                    <div className={styles.navTitleContent}>{title}</div>
                  </div>
                  <div className={styles.navDesc}>{desc}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.galleriesWrapper}>
          <div
            className={styles.galleries}
            style={{ transform: `translateY(${-100 * curr}%)` }}
          >
            {(list || []).map(({ step, title, desc, previews }) => {
              return (
                <div key={step} className={styles.gallery}>
                  <div className={styles.galleryPicsWrapper}>
                    <div
                      className={styles.galleryPics}
                      style={{ transform: `translateX(${-100 * icurr}%)` }}
                    >
                      {(previews || []).map((__, j) => (
                        <div key={j} className={styles.galleryPicWrapper}>
                          <div className={styles.clearBlock} />
                          <img
                            className={styles.galleryPic}
                            src={previews[j].src}
                            style={previews[j].itemStyle}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.galleryMask} />
                  <div className={styles.galleryContent}>
                    <h3 className={styles.galleryTitle}>{title}</h3>
                    <p className={styles.galleryDesc}>{desc}</p>
                    {previews && previews.length > 1 && (
                      <div className={styles.dots}>
                        {(previews || []).map((__, j) => (
                          <div
                            key={j}
                            className={cls([
                              styles.dot,
                              icurr === j && 'active',
                            ])}
                            onMouseEnter={() => goto(undefined, j)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
