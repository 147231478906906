import React, { useState, useEffect, useRef } from 'react';
import { on, off, addStyle, getOffset, scrollTop } from 'dom-lib';

import scrollTo from 'utils/scrollTo';

import styles from './style.module.less';

export default ({ menus }) => {
  const nav = useRef();

  useEffect(() => {
    let collapse = false;

    function handleScroll() {
      const $header = document.getElementById('header');
      const $nav = nav && nav.current;

      if (!$header || !$nav) return;

      const st = scrollTop(window);
      const shouldCollapse = st > 500;

      if (shouldCollapse && collapse) return;
      if (!shouldCollapse && !collapse) return;

      collapse = shouldCollapse;

      if (shouldCollapse) {
        addStyle($header, { top: '-80px', overflow: 'hidden' });
        addStyle($nav, 'top', '0px');
      } else {
        addStyle($header, { top: '0px', overflow: 'inherit' });
        addStyle($nav, 'top', '80px');
      }
    }

    on(window, 'scroll', handleScroll);

    handleScroll();

    return () => off(window, 'scroll', handleScroll);
  }, []);

  return (
    <div ref={nav} className={styles.menus}>
      <div className={styles.menusWrapper}>
        {(menus || []).map(({ id, title }, i) => {
          return (
            <span
              key={i}
              className={styles.menuItem}
              onClick={() => {
                scrollTo({ id });
              }}
            >
              <span>{title}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};
