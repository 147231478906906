import React, { useEffect } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import TopNav from 'components/TopNav';
import CHeadCarousel from 'components/CHeadCarousel';
import CardList from 'components/CardList';
import StepCarousel from 'components/StepCarousel';
import TeacherGallery from 'components/TeacherGallery';
import ActivityGallery from 'components/ActivityGallery';
import BookForm from 'components/BookForm';
import FixedBtn from 'components/FixedBtn';
import useCR from 'utils/useCR';
import track from 'utils/track';
import BookFrom from '../../components/BookForm';

export default ({ location: { search } }) => {
  const cr = useCR(search);

  useEffect(() => {
    track().pv('【指教音乐官网】少儿钢琴——页面访问');
  }, []);

  if (cr) {
    return (
      <iframe
        style={{ border: 'none' }}
        width={window.innerWidth}
        height={window.innerHeight}
        src="https://www.finger66.com/activity/teacher-join/#/?source=410000"
      />
    );
  }

  return (
    <>
      <Header instrument="piano" customer="child" />
      <TopNav
        menus={[
          { id: '', title: '课程介绍' },
          { id: 'card-list', title: '孩子能获得什么？' },
          { id: 'step-carousel', title: '上课现场' },
          { id: 'teacher-gallery', title: '师资团队' },
          { id: 'activity-env', title: '校区特色活动' },
          { id: 'book-form', title: '免费预约' },
        ]}
      />
      <CHeadCarousel
        list={[
          {
            step: '01',
            title: '《克里蒙奇钢琴教程》',
            desc:
              '一对一专业师资教学、美国|中国|加拿大3国专家共同研发、直通英皇考级',
            previews: [
              {
                src: require('images/piano/child/headerL-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '02',
            title: '「音乐星球」键盘启蒙课程',
            desc: '塑造音乐理论认知、训练综合音乐技能、培养思维感知能力',
            previews: [
              {
                src: require('images/piano/child/headerL-2.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '03',
            title: '独创3A教学法',
            desc: '从儿童心理学出发，结合40余年音乐教学经验打造的“3A教学法”。',
            previews: [
              {
                src: require('images/piano/child/headerL-3.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '04',
            title: '国际专家教研团队',
            desc: '由多位国际音乐专家组成的教研团队，打造每一节专业钢琴课。',
            previews: [
              {
                src: require('images/piano/child/headerL-4.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
        ]}
      />
      <CardList
        bgColor="light"
        title="孩子能获得什么？"
        list={[
          {
            src: require('images/piano/child/scaleL-1.jpg'),
            itemStyle: { maxWidth: 480 },
            title: '考级轻松过',
            content: [
              '课程内容直通英皇考级，孩子学习对应阶段课程后即可轻松考取专业水平证书。',
            ],
          },
          {
            src: require('images/piano/child/scaleL-2.jpg'),
            itemStyle: { maxWidth: 480 },
            title: '海量赛事参与机会',
            content: [
              '我们提供国内外专业音乐竞赛舞台、赛事指导与舞台设计，帮助孩子培养自信心与表现力。',
            ],
          },
          {
            src: require('images/piano/child/scaleL-3.jpg'),
            itemStyle: { maxWidth: 480 },
            title: '海外游学机会',
            content: [
              '优秀学员将有机会前往世界著名的美国卡耐基音乐厅演出、参加顶尖音乐学府学术交流活动。',
            ],
          },
          {
            src: require('images/piano/child/scaleL-4.jpg'),
            itemStyle: { maxWidth: 480 },
            title: '名师音乐课，零距离接触音乐名家',
            content: [
              '知名音乐教育家、钢琴家将定期开展大师课，孩子将有机会与名家同台、获得大师指导。',
            ],
          },
          {
            src: require('images/piano/child/scaleL-5.jpg'),
            itemStyle: { maxWidth: 480 },
            title: '可视化成长记录',
            content: [
              '学员可在APP端查看往期的上课记录、教师点评单等课程信息，点滴进步历历在目。',
            ],
          },
        ]}
      />
      <StepCarousel
        title="上课流程"
        description="互联网时代下的音乐教育模式"
        bgColor="gray"
        list={[
          {
            src: require('images/piano/child/step-1.jpg'),
            itemStyle: { maxWidth: 1920 },
            step: '01',
            title: '【课前】掌上课程表 随时查询课程信息',
            content: [
              '下载Finger指教APP端 随时管理孩子的课程表',
              '贴心的课前提醒 避免缺课、迟到等尴尬情况',
              '课程内容早知道 让孩子做好课前预习',
            ],
          },
          {
            src: require('images/piano/child/step-2.jpg'),
            itemStyle: { maxWidth: 1920 },
            step: '02',
            title: '【课中】一对一名师教学 专业教学环境',
            content: [
              '使用YAMAHA、KAWAI等进口品牌钢琴',
              '丰富的多媒体教学课件 让课堂充满趣味',
              '独立琴房+透视玻璃门 随时查看教学情况',
            ],
          },
          {
            src: require('images/piano/child/step-3.jpg'),
            itemStyle: { maxWidth: 1920 },
            step: '03',
            title: '【课后】反馈与评价系统 双向教学质量监督',
            content: [
              '家长可随时查看上课报告单、了解孩子表现',
              'APP端可查看演奏示范 孩子在家也能放心练习',
              '学员对老师的评价将成为重要的教师考核标准',
            ],
          },
        ]}
      />
      <TeacherGallery
        title="师资团队"
        content={[
          {
            title: '【教学研发团队】',
            desc:
              'Finger指教音乐中心的专家教研团队来自世界多家音乐学府，该团队由美籍华裔音乐教育家吴菀夷女士担任教学总监，成员来自于美国密苏里州立大学、皮博迪音乐学院、温哥华国际音乐学院、西安音乐学院、沈阳音乐学院等专业音乐院校。',
          },
          {
            title: '【音乐教师筛选】',
            desc:
              'Finger全体音乐教师均毕业于国内外专业音乐院校，拥有多年教学经验。每一名正式上岗的音乐教师在经过严格筛选、系统培训并考核后将由美国IMPACT音乐家协会、Finger指教音乐中心联合认证，并授予教师资格证书。',
          },
          {
            title: '【教学质量双向监督】',
            desc:
              '为保证每一位学员的课程体验，我们采取双向的教学质量监督模式。专家教研团队将定期开展教学研讨会议，不断优化课程内容并对教师的课堂技能进行专业考核；同时，学员在每一节课程结束后都可以对教师进行评价，学员满意度也将成为我们评估教师专业能力与教学实力的重要指标之一。',
          },
        ]}
        src={require('images/piano/child/teacherL-12.jpg')}
        itemStyle={{ maxWidth: 1920 }}
      />
      <ActivityGallery
        title="校区活动"
        description="趣味活动，陪伴孩子的兴趣教育"
        list={[
          {
            step: '01',
            title: '专属生日会',
            desc:
              '定制生日蛋糕、精美生日礼物、神秘嘉宾祝福，每一位正式学员均可享受专属生日会福利，拥有别样的“音乐生日会”。',
            previews: [
              {
                src: require('images/piano/child/activity-4.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '02',
            title: '“小小钢琴家”演奏会',
            desc:
              '每月一次，为儿童学员提供定期的舞台表现机会，同时通过多次舞台锻炼经历培养孩子的表现力。',
            previews: [
              {
                src: require('images/piano/child/activity-2.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '03',
            title: '节假日亲子活动',
            desc:
              '为琴童家长提供一个更好的亲子交流机会。生动有趣的节日亲子游戏会让爸爸妈妈更好地了解孩子的学习情况。',
            previews: [
              {
                src: require('images/piano/child/activity-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '04',
            title: '大型音乐会',
            desc: '每季一次，活动将选拔学习成果优异的学员同台竞技。',
            previews: [
              {
                src: require('images/piano/child/activity-3.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
        ]}
      />
      <BookForm
        title={
          <>
            免费预约价值
            <br />
            499元的美式少儿钢琴课程
          </>
        }
        instrument="piano"
        customer="child"
        placeholders={{
          schoolId: '选择校区',
          realName: '输入孩子的姓名',
          mobilePhone: '输入您（家长）的手机号',
          verifyCode: '输入您收到的验证码',
        }}
      />
      <FixedBtn />
      <Footer showCopyRight={cr} />
    </>
  );
};
